<template>
  <div
    class="h-screen flex w-full vx-row no-gutter items-center justify-center"
  >
    <div class="vx-col sm:w-1/2 md:w-1/2 lg:w-1/2 xl:w-3/5 sm:m-0 m-4">
      <vx-card>
        <div
          slot="no-body"
          class=""
        >
          <div class="vx-row no-gutter">
            <div
              class="
                vx-col
                sm:w-full
                md:w-1/2
                mx-auto
                self-center
                d-theme-dark-bg
              "
            >
              <div class="px-8 pt-8">
                <div class="vx-card__title mb-6">
                  <h4 class="mb-4">
                    Please wait as we confirming your email address....
                  </h4>

                  <!-- <h4 class="mb-4">Your email has been confirmed successfully. You will be notified once we launch!</h4> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </vx-card>
    </div>
  </div>
</template>

<script>

export default {
  components: {},
  created() {
    this.confirm_code(this.$route.params.code)
  },
  methods: {
    confirm_code(code) {
      this.$http
        .get(`/api/auth/confirm-account/${code}`)
        .then(response => {
          const cleaner = response.data
          if (cleaner.success) {
            this.$toast(
              {
                component: this.$toastContent,
                props: {
                  title: 'Confirmation Successful!',
                  icon: 'AlertCircleIcon',
                  text: cleaner.message,
                  variant: 'success',
                },
              },
              { timeout: this.$longestTimeout },
            )
            this.$router.push('/auth/login')
          } else if (!cleaner.success || cleaner.errors) {
            this.$toast(
              {
                component: this.$toastContent,
                props: {
                  title: 'Confirmation Failed!',
                  icon: 'AlertCircleIcon',
                  text: response.data.errors[0],
                  variant: 'danger',
                },
              },
              { timeout: this.$longestTimeout },
            ) // this.$router.push('/auth/login')
          }
        })
        .catch(error => {
          this.$toast(
            {
              component: this.$toastContent,
              props: {
                title: 'Confirmation Failed!',
                icon: 'AlertCircleIcon',
                text: error[0],
                variant: 'danger',
              },
            },
            { timeout: this.$longestTimeout },
          ) //   this.$router.push(this.$router.currentRoute.query.to || '/auth/login')
        })
    },
  },
}
</script>

<style lang="scss">
.register-tabs-container {
  min-height: 517px;

  .con-tab {
    padding-bottom: 23px;
  }
}
</style>
